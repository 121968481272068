// extracted by mini-css-extract-plugin
export var area = "styles-module--area--82c3b";
export var blackish = "#121117";
export var colors = "\"../../../assets/css/_colors.css\"";
export var fonts = "\"../../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var info = "styles-module--info--24f5c";
export var lightBg = "#FFF8E8";
export var location = "styles-module--location--498b7";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var row = "styles-module--row--b7ea3";
export var title = "styles-module--title--8d599";
export var title_row = "styles-module--title_row--7bb23";
export var yellowBtn = "#FFE07B";