import React from "react"
import Button from "../../button"
import * as styles from "./styles.module.css"

const JobOpening = ({ data }) => {
    return (
        <div className={styles.row}>
            <div className={`${styles.title_row} flex space-between align-center`}>
                <h3>{data.title}</h3>
                <Button
                    type="purple"
                    text="Learn More"
                    url={`/${data.slug}`}
                    newTab={false}
                />
            </div>
            <div className={styles.info}>
                <div className={styles.area}>
                    <p className={styles.title}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1482_7875)">
                                <path d="M1 5V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15 5V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H11" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1 11V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15 11V14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15H11" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8 5V11" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5 8H11" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1482_7875">
                                    <rect width="16" height="16" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        Area
                    </p>
                    <p>{data.area}</p>
                </div>
                <div className={styles.location}>
                    <p className={styles.title}>
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0128 6.13333C11.0128 10.3333 5.87943 15 5.87943 15C5.87943 15 0.746094 10.3333 0.746094 6.13333C0.746094 4.77189 1.28693 3.46621 2.24961 2.50352C3.2123 1.54083 4.51798 1 5.87943 1C7.24087 1 8.54656 1.54083 9.50924 2.50352C10.4719 3.46621 11.0128 4.77189 11.0128 6.13333V6.13333Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.88021 8.46647C7.16887 8.46647 8.21354 7.4218 8.21354 6.13314C8.21354 4.84447 7.16887 3.7998 5.88021 3.7998C4.59154 3.7998 3.54688 4.84447 3.54688 6.13314C3.54688 7.4218 4.59154 8.46647 5.88021 8.46647Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Location
                    </p>
                    <p>{data.location}</p>
                </div>
            </div>
        </div>
    )
}
export default JobOpening