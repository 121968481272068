import React from "react"
import JobOpening from "./jobOpening"

const CurrentOpenings = ({ headline, openings }) => {
    return (
        <div className="container-fluid">
            <div className="row pb-120 pb-60-tablet">
                <div className="col-lg-5 pl-0 pl-12-tablet">
                    <h2 className="text-left m-0 pb-40-tablet mobile-h3">{headline}</h2>
                </div>
                <div className="col-lg-7">
                    {openings.map((block, index) => 
                        <JobOpening data={block} key={index}/>
                    )}
                </div>
            </div>
        </div>
    )
}
export default CurrentOpenings