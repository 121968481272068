import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import GenericBanner from "../components/genericBanner"
import CurrentOpenings from "../components/currentOpenings"
import Cta from "../components/cta"

export default function Hiring({ data: { page }}) {
    return(
        <Layout>
          {page.banner.map((block, index) =>
              <GenericBanner key={index} banner={block} title={page.title} marginTop={60} marginBottom={120} mobileMarginBottom={60}/>
          )}
          <CurrentOpenings headline={page.headline} openings={page.currentOpenings}/>
          {page.cta.map((block, index) =>
            <Cta 
                key={index} 
                data={block} 
                type={`${block.model.apiKey === "shapes_cta" ? "shapes" : "default"}`}
            />
          )}
        </Layout>
    )
}

export const data = graphql`
query HiringQuery {
    page: datoCmsHiring {
        title
        banner {
            backgroundImage {
              alt 
              data: gatsbyImageData
              url
            }
            mobileBackgroundImage {
              alt 
              data: gatsbyImageData
              url
            }
        }
        headline
        currentOpenings {
          slug
          title
          applicationUrl
          area
          location
        }
        cta {
          ... on DatoCmsShapesCta {
              model {
              apiKey
              }
              headline
              body
              shapeType
              buttonText
              buttonUrl
              newTab
          }
        }
        seo {
            title
            description
            image {
              url
            }
        }
    }
  }
`

export const Head = ({ data }) => {
    return (
      <Seo 
        title={data.page.seo.title}
        description={data.page.seo.description}
        image={data.page.seo.image}
      />
    )
  }